<template>
  <div class="container">
    <div class="header-container">
      <header>
        <van-nav-bar
            :title="title"
            left-text="返回"
            right-text=""
            left-arrow
            @click-left="onBack"
        />
      </header>
    </div>
    <div class="section-box" style="padding: 10px" v-html="content">
    </div>
    <Loading :loding-txt="lodingTxt" :is-show="isShow"/>
  </div>
</template>

<script>
import {uploadDemo} from "../../api/other";
import Loading from "../../components/logding";

export default {
  name: "uploaddemo",
  components: { Loading },
  data(){
    return{
      isShow : false,
      lodingTxt: '加载中...',
      title: this.$route.query.title,
      key: this.$route.query.key,
      content:''
    }
  },
  created() {
    this.uploadDemo()
  },
  methods: {
    onBack: function () {
      this.$router.go(-1);
    },
    uploadDemo(){
      const that = this
      that.isShow = true
      that.lodingTxt = '加载中...'
      uploadDemo(that.key).then(response => {
        console.log(response)
        that.isShow = false
        if (response.code == process.env.VUE_APP_SUCCESS_CODE){
          const data = response.data
          that.content = data.infoContent
          console.log(data)
        }else{
          that.$toast(response.message)
        }
      }).catch(() => {
        that.isShow = false
        that.$toast('服务器错误，请稍后再试！')
      })
    }
  }
}
</script>

<style scoped>

</style>